<div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-light header">
        <a href="#" class="navbar-brand">{{ 'UI.COMMON.APPLICATION.TITLE' | translate }}</a>
            <ul class="navbar-nav ml-auto lang_menu" *ngIf="router.url!='/login' && !isLoggedIn">
                <a class="js-hover lang_menu_item" (click)="toggleLangMenu()" [ngClass]="{ 'is-open' : isOpen}">{{ "Language" }}</a>
                <ul class="languageBar__body" id="language-bar" [ngStyle]="{'display':isOpen? 'block' : 'none'}">
                    <li><a id="jaLang" class="js-hover languageBar__item" style="text-align:center;" (click)="changeLang('ja')">
                        <span>{{ "日本語" }}</span></a></li>
                    <li><a id="enLang" class="js-hover languageBar__item" style="text-align:center;" (click)="changeLang('en')">
                        <span>{{ "EN" }}</span></a></li>
                </ul>
            </ul>
            <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
                               <li class="nav-item">
                                   <a class="nav-link">{{ username }}</a>
                               </li>
                               <li class="nav-item">
                                   <a href class="nav-link" (click)="logout()">LogOut</a>
                             </li>
                         </ul>
    </nav>

    <div class="app-container">
        <router-outlet></router-outlet>
    </div>
</div>