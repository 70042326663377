import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


import { TokenStorageService } from '../services/token-storage.service';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../app.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private translate: TranslateService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set(Constants.TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    return next.handle(authReq).pipe(
      catchError((err) => {
        if (!err.error.message){
          switch (err.error.status) {
            case 401:
              err.error.message = this.translate.instant('UI.HTTP.ERROR_CODE.MESSAGES.LOGIN.401');
              break;
            default:
              err.error.message = this.translate.instant('UI.HTTP.ERROR_CODE.MESSAGES.500');
              break;
          }
        }
        return throwError(err);
      }));
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];