<div class='main_container'>
    <div class="box">
        <h3 class="text-center">
            {{ 'UI.REQUESTS.HEADING' | translate }}
        </h3>
        <div class='content'>
            {{ errorMessage }}
            <div class='text-right'>
                <a class='btn btn-primary' routerLink="/requests/new">New Request</a>
            </div>
            <div class="table-responsive">
                <table class='table'>
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Status</th>
                            <th scope="col">Farmers Emails</th>
                            <th scope="col">CreatedOn</th>
                            <th scope="col">UpdatedOn</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let serviceRequest of serviceRequests">
                            <td>{{ serviceRequest.id }}</td>
                            <td>{{ serviceRequest.email }}</td>
                            <td>{{ serviceRequest.status }}</td>
                            <td>
                                <ol>
                                    <li *ngFor="let farmerEmail of serviceRequest.requestData">{{farmerEmail}}</li>
                                </ol>
                            </td>
                            <td>{{ serviceRequest.createdOn | dateTimeFormat }}</td>
                            <td>{{ serviceRequest.updatedOn | dateTimeFormat }}</td>
                            <td><a class='btn btn-primary' routerLink="/requests/{{serviceRequest.id}}">Show</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>