import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbortDeletionRequestInterface } from 'src/app/interfaces/abort-deletion-request-interface';
import { RequestsService } from 'src/app/services/requests.service';
import { Constants } from 'src/app/app.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abort-data-deletion-request',
  templateUrl: './abort-data-deletion-request.component.html',
  styleUrls: ['./abort-data-deletion-request.component.css']
})
export class AbortDataDeletionRequestComponent implements OnInit {

  abortDeletionRequest!: AbortDeletionRequestInterface;
  isAbortInitiated = false;
  errorMessage = null;
  email = null;
  reason = null;
  status = 'N/A';
  deletetionCompleted = false;
  reasonInput: boolean  = true;
  emailInput: boolean  = true;
  regionCode:String='';

  abortRequest= new FormGroup({})

  constructor(
    private requestsService: RequestsService,
    private route: ActivatedRoute,
    public translate: TranslateService
    ) 
    {
      translate.addLangs(['en','ja']);
      translate.setDefaultLang('en');
    }    
    

  ngOnInit(): void {
    const abortLinkHash = String(this.route.snapshot.paramMap.get('abortLinkHash'));
    this.regionCode=String(this.route.snapshot.paramMap.get('regionCode'));
    if(this.regionCode=='JP'){
      this.translate.use('ja');
    }
    this.requestsService.getAbortRequestDetails(abortLinkHash).subscribe(
      data => {
        this.abortDeletionRequest = data;
        this.errorMessage = null;
        if (this.abortDeletionRequest.status === Constants.COMPLETED){
          this.isAbortInitiated = true;
          this.status = this.abortDeletionRequest.status;          
        } else if (this.abortDeletionRequest.status === Constants.DELETION_COMPLETED){
          this.deletetionCompleted = true
          this.status = this.abortDeletionRequest.status;
        }
      },
      err => {
        this.errorMessage = err.error.message;
      }
    );

    this.abortRequest = new FormGroup({
      email: new FormControl('', [Validators.required]),
      reason: new FormControl('', [Validators.required]),
    })
  }

  submit(): void{
    if(this.vaidateForm()) {
      const abortLinkHash = String(this.route.snapshot.paramMap.get('abortLinkHash'));
      this.requestsService.createAbortRequestDetails(abortLinkHash, String(this.email), String(this.reason)).subscribe(
        data => {
          this.abortDeletionRequest = data;
          this.isAbortInitiated = true;
          this.status = this.abortDeletionRequest.status;
          this.errorMessage = null;
        },
        err => {
          this.errorMessage = err.error.message;
        }
      );
    }
  }

  vaidateForm(): boolean {
    this.errorMessage = null;
    if (!this.abortRequest.get('reason')?.value && !this.abortRequest.get('email')?.value) {
      this.reasonInput = false;
      this.emailInput = false;
      return false;
    } else if (!this.abortRequest.get('email')?.value && this.abortRequest.get('reason')?.value) {
      this.emailInput = false;
      this.reasonInput = true;
      return false;
    } else if (!this.abortRequest.get('reason')?.value && this.abortRequest.get('email')?.value) {
      this.reasonInput = false;
      this.emailInput = true;
      return false;
    }
    this.emailInput = true;
    this.reasonInput = true;
    return true;
  }
}