import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from './app.constants';
import { TokenStorageService } from './services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      switch (this.tokenStorageService.getUserStatus()) {
        case Constants.UN_AUTHENTICATED:
          this.tokenStorageService.signOut();
          this.router.navigateByUrl('/login');
          break;
        case Constants.VERIFICATION_PENDING:
            this.router.navigateByUrl('/validate-passcode');
            break;
        default:
          break;
      }
      return true;
  }
}