import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../app.constants';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {

    //convert to UTC
    var date = new Date(value);
    date.setUTCFullYear(date.getFullYear());
    date.setUTCMonth(date.getMonth());
    date.setUTCDate(date.getDate());
    date.setUTCHours(date.getHours());
    date.setUTCMinutes(date.getMinutes())
    date.setUTCSeconds(date.getSeconds())
    date.setUTCMilliseconds(date.getMilliseconds());

    return super.transform(date, Constants.DATE_TIME_FMT);
  }
}