import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountLoginComponent } from './components/accounts/account-login/account-login.component';

import { authInterceptorProviders } from './helpers/auth.interceptor';
import { NewRequestComponent } from './components/requests/new-request/new-request.component';
import { RequestsComponent } from './components/requests/requests/requests.component';
import { AbortDataDeletionRequestComponent } from './components/requests/abort-data-deletion-request/abort-data-deletion-request.component';
import { RequestDetailsComponent } from './components/requests/request-details/request-details.component';
import { ValidatePasscodeComponent } from './components/accounts/validate-passcode/validate-passcode.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AccountLoginComponent,
    NewRequestComponent,
    RequestsComponent,
    AbortDataDeletionRequestComponent,
    RequestDetailsComponent,
    ValidatePasscodeComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}