import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { TokenStorageService } from '../../../services/token-storage.service';
import { Router } from '@angular/router';
import { AppComponent} from '../../../app.component';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.css']
})
export class AccountLoginComponent implements OnInit {

  form: any = {
    emailAddress: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(
    private appComponent: AppComponent,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.appComponent.isLoggedIn && (this.tokenStorage.getToken() !== null);
    if (this.isLoggedIn){
      this.router.navigate(['/requests']);
    }
  }

  onSubmit(): void {
    this.authService.login(this.form.emailAddress.toLowerCase(), this.form.password).subscribe(
      data => {
        this.tokenStorage.saveToken(data.access_token, data.refresh_token, data.expired_time, data.customUserDetails);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.appComponent.isLoggedIn = true;
        this.appComponent.username = this.tokenStorage.getUser().emailAddress;
        this.router.navigate(['/requests']);
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }
}