import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './authentication.guard';

import { AccountLoginComponent } from './components/accounts/account-login/account-login.component';
import { NewRequestComponent } from './components/requests/new-request/new-request.component';
import { RequestsComponent } from './components/requests/requests/requests.component';
import { RequestDetailsComponent } from './components/requests/request-details/request-details.component';
import { AbortDataDeletionRequestComponent } from './components/requests/abort-data-deletion-request/abort-data-deletion-request.component';
import { ValidatePasscodeComponent } from './components/accounts/validate-passcode/validate-passcode.component';

const routes: Routes = [
  { path: '', component: AccountLoginComponent, pathMatch: 'full' , canActivate: [AuthenticationGuard] },
  { path: 'login', component: AccountLoginComponent },
  { path: 'validate-passcode', component: ValidatePasscodeComponent},
  { path: 'requests', component: RequestsComponent, canActivate: [AuthenticationGuard] },
  { path: 'requests/new', component: NewRequestComponent, canActivate: [AuthenticationGuard]  },
  { path: 'requests/:id', component: RequestDetailsComponent, canActivate: [AuthenticationGuard]  },
  { path: 'requests/abort-data-deletion/:abortLinkHash/region_code/:regionCode', component: AbortDataDeletionRequestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }