<div class="container">
    <main class="main_container">
        <div class='box'>
            <h3 class="text-center">{{ 'UI.COMMON.PASSCODE_VERIFICATION' | translate }}</h3>
            <form name="passcodeVerificationForm" (ngSubmit)="f.form.valid && verifyPasscode()" #f="ngForm" novalidate>
                <div class="alertSummary ng-hide" role="alert" *ngIf="mainError">
                    {{ mainError }}
                </div>
                <div class="alertSummary" *ngIf="f.form.touched && passcode.errors">
                    <div *ngIf="passcode.errors.required">{{ 'UI.PASSCODE_VERIFICATION.PASSCODE_REQUIRED_MSG' | translate }}</div>
                    <div *ngIf="passcode.errors.minlength">{{ 'UI.PASSCODE_VERIFICATION.PASSCODE_LENGTH_MSG' | translate: { passcodeLength: passcodeLength } }}</div>
                </div>
                <div class="alertSummary info" *ngIf="counter && counter > 0" [innerHtml]="'UI.COMMON.DSTE0004' | translate: { counter: counter }"></div>

                <div class="alertSummary info" *ngIf="infoMessageIndex != null && infoMessageIndex == 0">
                    {{ 'UI.PASSCODE_VERIFICATION.SEND_EMAIL_SUCCESS_MSG' | translate }}
                </div>

                <div class="alertSummary info" *ngIf="infoMessageIndex != null && infoMessageIndex == 1">
                    {{ 'UI.PASSCODE_VERIFICATION.RESEND_EMAIL_SUCCESS_MSG' | translate }}
                </div>
                <div class='loginForm'>
                    <div class="form-group" [ngClass]="{ 'has-error' : f.form.invalid}">
                        <div class="row"><label class="col-md-12" for="passcode">{{ 'UI.PASSCODE_VERIFICATION.PASSCODE_LABEL' | translate }}<span class="mandatory_field">*</span></label></div>
                        <div class="row mb-2">
                            <div class="col-md-8">
                                <input class="form-control" type="text" name="passcode" minlength="{{passcodeLength}}" maxlength="{{passcodeLength}}" [(ngModel)]="passcodeVerificationForm.passcode" #passcode="ngModel" required>
                            </div>
                            <div class="col-md-4">
                                <button type="button" [disabled]="counter && counter > 0" (click)="resendPasscodeEmail()" class="btn btn-primary btn-block resend-btn">{{ 'UI.PASSCODE_VERIFICATION.RESEND_BUTTON' | translate }}</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="offset-md-4 col-md-4">
                                <button [disabled]="!f.valid" class="btn btn-primary btn-block">{{ 'UI.PASSCODE_VERIFICATION.VERIFY_BUTTON' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>