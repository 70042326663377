import { Component, OnInit } from '@angular/core';
import { ServiceRequestInterface } from 'src/app/interfaces/service-request-interface';
import { RequestsService } from 'src/app/services/requests.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css']
})

export class RequestDetailsComponent implements OnInit {

  serviceRequestDetails!: ServiceRequestInterface;
  errorMessage = null;
  isDataLoaded = false;

  constructor(
    private requestsService: RequestsService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.requestsService.getRequestDetails(id).subscribe(
      data => {
        this.serviceRequestDetails = data;
        this.isDataLoaded = true;
        this.errorMessage = null;
      },
      err => {
        this.errorMessage = err.error.message;
      }
    );
  }
}