import { Component, OnInit } from '@angular/core';
import { RequestsService } from 'src/app/services/requests.service';
import { ServiceRequestInterface } from 'src/app/interfaces/service-request-interface';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  serviceRequests: Array<ServiceRequestInterface> = [];
  errorMessage = null;

  constructor(private requestsService: RequestsService) { }

  ngOnInit(): void {
    this.requestsService.getRequests().subscribe(
      data => {
        document.getElementsByTagName('body')[0].classList.remove("loading");
        this.serviceRequests = data;
      },
      err => {
        this.errorMessage = err.message;
      }
    );
  }

}
