<div class='main_container'>
    <div class="box">
        <h3 class="text-center">
            {{ 'UI.REQUEST_DETAIL.HEADING' | translate }}
        </h3>
        <div class='content'>
            <div class="alertSummary ng-hide" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div *ngIf="isDataLoaded && !errorMessage">
                <div class="table-responsive">
                    <table class='table'>
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Created By</th>
                                <th scope="col">Status</th>
                                <th scope="col">Farmers Emails</th>
                                <th scope="col">CreatedOn</th>
                                <th scope="col">UpdatedOn</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ serviceRequestDetails.id }}</td>
                                <td>{{ serviceRequestDetails.email }}</td>
                                <td>{{ serviceRequestDetails.status }}</td>
                                <td>
                                    <ol>
                                        <li *ngFor="let farmerEmail of serviceRequestDetails.requestData">{{farmerEmail}}</li>
                                    </ol>
                                </td>
                                <td>{{ serviceRequestDetails.createdOn | dateTimeFormat }}</td>
                                <td>{{ serviceRequestDetails.updatedOn | dateTimeFormat }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class='mb-4' *ngIf="serviceRequestDetails.farmerDataDeletionRequests.length > 0">
                    <h4 class='text-center mb-3'><u>{{ 'UI.REQUEST_DETAIL.DELETION_REQUESTS.HEADING' | translate }}</u></h4>
                    <div id="deleteRequestsAccordion">
                        <div class="card border-success" *ngFor="let deleteRequest of serviceRequestDetails.farmerDataDeletionRequests">
                            <div class="card-header" id="heading{{deleteRequest.id}}" data-toggle="collapse" [attr.data-target]="'#deleteRequestCollapse' + deleteRequest.id" aria-expanded="true">
                                <h5 class="mb-0 pointer-cursor">
                                    {{ deleteRequest.farmerInfo.email}}
                                    <span class="badge badge-primary">{{ deleteRequest.status }}</span>

                                    <span class='float-right'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16">
                                            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                                            <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
                                        </svg>
                                        <span class='time'>{{ deleteRequest.createdOn | dateTimeFormat }}</span>
                                    </span>
                                </h5>
                            </div>

                            <div id="deleteRequestCollapse{{deleteRequest.id}}" class="collapse" data-parent="#deleteRequestsAccordion">
                                <div class="card-body">
                                    <table class='m-auto table'>
                                        <tr>
                                            <td class='font-weight-bold text-right'>Created On:</td>
                                            <td>{{ deleteRequest.createdOn | dateTimeFormat }}</td>
                                        </tr>
                                        <tr>
                                            <td class='font-weight-bold text-right'>Updated On:</td>
                                            <td>{{ deleteRequest.updatedOn | dateTimeFormat }}</td>
                                        </tr>
                                    </table>
                                    <h5>Activities:</h5>
                                    <div id="requestActivitesAccordion">
                                        <div class="card border-success" *ngFor="let requestActivity of deleteRequest.requestActivitiesList">
                                            <div class="card-header" id="heading{{requestActivity.id}}" data-toggle="collapse" [attr.data-target]="'#requestActivityCollapse' + requestActivity.id" aria-expanded="true">
                                                <h5 class="mb-0 pointer-cursor">
                                                    {{ requestActivity.name}}
                                                    <span class="badge badge-primary">{{ requestActivity.status }}</span>

                                                    <span class='float-right'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch" viewBox="0 0 16 16">
                                                            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"/>
                                                            <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"/>
                                                        </svg>
                                                        <span class='time'>{{ requestActivity.createdOn | dateTimeFormat }}</span>
                                                    </span>
                                                </h5>
                                            </div>

                                            <div id="requestActivityCollapse{{requestActivity.id}}" class="collapse" data-parent="#requestActivitesAccordion">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Status</th>
                                                                    <th>CreatedOn</th>
                                                                    <th>UpdatedOn</th>
                                                                    <th>ErrorMessage</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let subActivity of requestActivity.subActivitiesList">
                                                                    <td>{{ subActivity.name }}</td>
                                                                    <td>{{ subActivity.status }}</td>
                                                                    <td>{{ subActivity.createdOn | dateTimeFormat }}</td>
                                                                    <td>{{ subActivity.updatedOn | dateTimeFormat }}</td>
                                                                    <td>{{ subActivity.errorMessage }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-primary" routerLink="/requests">{{'UI.COMMON.BACK_BUTTON' | translate }}</button>
        </div>
    </div>