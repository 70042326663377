<div class="container">
    <main class="container__main">
        <div class="login-box">
            <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div class="alertSummary ng-hide" role="alert" *ngIf="emailAddress.errors && f.touched">
                    <div *ngIf="emailAddress.errors.required">{{ 'UI.ERRORS.MESSAGE.EMAIL_REQUIRED' | translate }}</div>
                    <div *ngIf="emailAddress.errors.pattern">{{ 'UI.ERRORS.MESSAGE.INVALID_EMAIL_PATTERN' | translate }}</div>
                </div>
                <div class="alertSummary ng-hide" role="alert" *ngIf="password.errors && f.touched">
                    <div *ngIf="password.errors.required">{{ 'UI.ERRORS.MESSAGE.PASSWORD_REQUIRED' | translate }}</div>
                    <div *ngIf="password.errors.minlength">
                        {{ 'UI.ERRORS.MESSAGE.PASSWORD_MIN_LENGTH' | translate }}
                    </div>
                </div>
                <div class="alertSummary ng-hide" role="alert" *ngIf="f.submitted && isLoginFailed">
                    {{ errorMessage }}
                </div>

                <div class='loginForm'>
                    <h3 class="text-center ng-binding">{{ 'UI.COMMON.LOGIN_HEADING' | translate }}</h3>

                    <div class="form-group">
                        <label for="emailAddress">{{ 'UI.COMMON.EMAIL.LABEL' | translate }}</label><span class="mandatory_field">*</span>
                        <input type="text" class="form-control" name="emailAddress" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="form.emailAddress" required #emailAddress="ngModel" />
                    </div>
                    <div class="form-group">
                        <label for="password">{{ 'UI.COMMON.PASSWORD.LABEL' | translate }}</label><span class="mandatory_field">*</span>
                        <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary btn-block" [disabled]="!f.valid">{{ 'UI.COMMON.LOGIN_BUTTON' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>