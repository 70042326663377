<div class='main_container'>
    <div class="box">
        <h3 class="text-center">
            {{ 'UI.REQUESTS.NEW.HEADING' | translate }}
        </h3>
        <div class="alertSummary ng-hide" role="alert" *ngIf="duplicatedEmail">
            {{ 'UI.ERRORS.MESSAGE.EMAIL_DUPLICATED' | translate }}
        </div>
        <div class='content'>
            <form name="form" class='form-horizontal' [formGroup]="formNewRequest" (ngSubmit)="onSubmit()" novalidate>
                <div class="row form-group">
                    <div class="offset-sm-1 col-sm-4">
                        <label class='control-label'>{{'UI.REQUESTS.TYPE.LABEL' | translate }}:</label>
                    </div>
                    <div class="col-sm-4">
                        <input formControlName="requestType" name="form.type" class="form-control" type="text" readonly />
                    </div>
                </div>

                <div class="row form-group">
                    <div class="offset-sm-1 col-sm-4">
                        <label class='control-label'>{{'UI.COMMON.EMAILS.LABEL' | translate }}:</label>
                    </div>
                    <div class="col-sm-4" formArrayName="emails">                       
                        <div class="input-group mb-1" *ngFor="let emailInput of emails.controls; let i = index">
                            <input class="form-control" type="email" [formControlName]="i" placeholder="Email" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required>
                            <div class="input-group-prepend" *ngIf="i">
                                <button (click)="deleteFieldValue(i)" class="btn btn-default btn-outline-secondary" type="button">&times;</button>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="row form-group">
                    <div class='offset-sm-5 col-sm-4'>
                        <a [routerLink]="" (click)="addNewField()">Add New email</a>
                    </div>
                </div>
                <div class="row form-group form-footer">
                    <div class="col-sm-3 offset-sm-3">
                        <button type="submit" class="btn btn-primary form-control" [disabled]="!formNewRequest.valid || !this.isShownCreated" >{{'UI.COMMON.CREATE_BUTTON' | translate }}</button>
                    </div>
                    <div class="col-sm-3">
                        <button type="button" class="btn btn-default form-control" (click)="cancel()">{{'UI.COMMON.CANCEL_BUTTON' | translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>