import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from './services/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  isLoggedIn = false;
  isPasscodeVerified = false;
  username?: string;
  isOpen=false;

  constructor(
    private tokenStorageService: TokenStorageService,
    public translate: TranslateService,
    private authService: AuthService,
    public router: Router

    ) {
    translate.addLangs(['en','ja']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.authService.currentStatusLogin.subscribe(res => this.isLoggedIn = res);
    if (this.tokenStorageService.isUserAuthenticated()){
      this.isLoggedIn = !!this.tokenStorageService.getToken();
      this.username = this.tokenStorageService.getUser().emailAddress;
    }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
  toggleLangMenu() {	
    this.isOpen = !this.isOpen	
  }	
  changeLang(lang: string) {	
    this.translate.use(lang);	
  }
}
