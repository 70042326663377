import { Injectable } from '@angular/core';
import { Constants } from '../app.constants';
import { AuthoritiesInterface } from '../interfaces/authorities-interface';
import { UserDetailsInterface } from '../interfaces/user-details-interface';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(accessToken: string, refreshToken: string, expiresAt: string, userDetails: UserDetailsInterface): void {
    window.localStorage.setItem(Constants.ACCESS_TOKEN, accessToken);
    window.localStorage.setItem(Constants.REFRESH_TOKEN, refreshToken);
    window.localStorage.setItem(Constants.EXPIRES_AT, expiresAt);
    window.localStorage.setItem(Constants.USER_DETAILS, JSON.stringify(userDetails));
  }

  public getToken(): string | null {
    return window.localStorage.getItem(Constants.ACCESS_TOKEN);
  }

  public getExpiresAt(): any {
    return window.localStorage.getItem(Constants.EXPIRES_AT);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(Constants.USER_DETAILS);
    window.localStorage.setItem(Constants.USER_DETAILS, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(Constants.USER_DETAILS);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getUserRoles(): any{
    const userDetails: UserDetailsInterface = this.getUser();
    return userDetails.authorities.map( (i: AuthoritiesInterface) => i.authority);
  }

  public getUserStatus(): string {
    const token = this.getToken();
    const isFutureTime = (this.getExpiresAt() - Date.now() > 0);
    if (token && isFutureTime){
      const userRoles = this.getUserRoles();
      if (userRoles.indexOf(Constants.ROLE_VERIFICATION_PENDING) !== -1){
        return Constants.VERIFICATION_PENDING;
      }
      return Constants.AUTHENTICATED;
    }
    return Constants.UN_AUTHENTICATED;
  }

  public isUserAuthenticated(): boolean {
    return true;
  }
}