import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(private http: HttpClient) { }

  getRequests(): Observable<any>{
    document.getElementsByTagName('body')[0].classList.add("loading");
    return this.http.get(Constants.API_ROUTES.SERVICE_REQUESTS);
  }

  createRequest(emailsList: Array<string>, type: string): Observable<any>{
    document.getElementsByTagName('body')[0].classList.add("loading");
    const reqData = emailsList;
    return this.http.post(Constants.API_ROUTES.SERVICE_REQUESTS, { reqData, type });
  }

  getRequestDetails(id: number): Observable<any> {
    const reqDetailsURL = `${Constants.API_ROUTES.SERVICE_REQUESTS}/${id}`;
    return this.http.get(reqDetailsURL);
  }

  getAbortRequestDetails(hash: string): Observable<any> {
    return this.http.get(Constants.API_ROUTES.ABORT_REQUEST_STATUS, { params: { hash } });
  }

  createAbortRequestDetails(hash: string, email: string, reason: string): Observable<any> {
    return this.http.post(Constants.API_ROUTES.ABORT_REQUESTS, { email, reason, hash });
  }
}