import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { RequestsService } from '../../../services/requests.service';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.css']
})
export class NewRequestComponent implements OnInit {

  form: any = {
    type: Constants.REQUEST_TYPES.FARMER_DATA_DELETION,
    emailsList: [],
    errorMessage: '',
  };

  formNewRequest = this.fb.group({
    requestType: [Constants.REQUEST_TYPES.FARMER_DATA_DELETION],
    emails: this.fb.array([
      this.fb.control('',Validators.required)
    ])
  })
  
  duplicatedEmail: boolean = false;

  isShownCreated: boolean = true;

  postId: any;

  constructor(
    private requestsService: RequestsService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
   
  }

  onSubmit(): void {
    if(this.hasDuplicatedEmail()){
      this.duplicatedEmail=true;
      return;
    }
    this.isShownCreated = false;
    let emailsList = this.emails.value as Array<string> ;
    this.requestsService.createRequest(emailsList, this.requestType.value).subscribe(
      data => {
        document.getElementsByTagName('body')[0].classList.remove("loading");
        this.router.navigate(['/requests']);
      },
      err => {
        this.form.errorMessage = err.error.message;
      }
    );
  }

  cancel(): void{
    this.router.navigate(['/requests']);
  }

  deleteFieldValue(index: number): void{
      this.emails.removeAt(index);
  }
  

  get emails() {
    return this.formNewRequest.get('emails') as FormArray;
  }

  get requestType(){
    return this.formNewRequest.get('requestType') as FormControl;
  }

  addNewField(): void{
    this.emails.insert(this.emails.length,this.fb.control('',Validators.required));
  }

  hasDuplicatedEmail(): boolean{
    let emailsList = this.emails.value as Array<string> ;
    let emailsListTemp = JSON.parse(JSON.stringify(emailsList));
    let setEmail = new Set(emailsListTemp);

    return setEmail.size !== emailsList.length;
  }

}