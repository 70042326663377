import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginStatusSource = new BehaviorSubject(false);
  currentStatusLogin = this.loginStatusSource.asObservable();

  constructor(private http: HttpClient) { }

  login(emailAddress: string, password: string): Observable<any> {

    return this.http.post(Constants.API_ROUTES.LOGIN, {
      emailAddress, password
    });
  }

  changeMessage(loginStatus: boolean) {
    this.loginStatusSource.next(loginStatus);
  }
}