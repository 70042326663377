import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { Router } from '@angular/router';
import { PasscodeService } from 'src/app/services/passcode.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-validate-passcode',
  templateUrl: './validate-passcode.component.html',
  styleUrls: ['./validate-passcode.component.css']
})
export class ValidatePasscodeComponent implements OnInit {

  mainError = '';
  infoMessageIndex: number | null = null;
  counter = 0;
  passcodeLength = 4;
  passcodeResendCounter = 0;
  passcodeResendDuration = 30;
  passcodeVerificationForm: any = {
    passcode: null
  };

  loginStatus = true;

  constructor(
    private passcodeService: PasscodeService, 
    private tokenStorageService: TokenStorageService, 
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.currentStatusLogin.subscribe(res => this.loginStatus = res);

    let token = this.tokenStorageService.getToken();
    if(token != null){
      const userRoles = this.tokenStorageService.getUserRoles();
      if(userRoles.indexOf(Constants.ROLE_PASSCODE_VERIFIED) !== -1){
        this.router.navigateByUrl('/requests');
        return;
      }
    } else {
      this.router.navigateByUrl('/requests');
      return;
    }
    
    this.resendPasscodeEmail();
  }

  resendPasscodeEmail(): void {
    if (this.passcodeResendCounter >= 3){
      this.tokenStorageService.signOut();
      this.router.navigateByUrl('/login');
    }
    if (this.passcodeResendCounter < 3 && this.counter === 0 ){
      this.passcodeService.sendPasscode().subscribe(
        data => {
          this.passcodeResendCounter++;
          this.initiateCountdown(this.passcodeResendDuration);
          // needs to be uncommented once API returns reSentTimes to avoid the counter to restart when page is refreshed
          // this.initiateCountdown(data.timeToWait);
          // this.passcodeResendCounter = data.reSentTimes;
        },
        err => {
          if (err.error.returnCode === 'E0006'){
            this.initiateCountdown(err.error.timeToWait);
            // needs to be uncommented once API returns reSentTimes to avoid the counter to restart when page is refreshed
            // this.passcodeResendCounter = err.error.reSentTimes;
          }
          else{
            this.mainError = err.error.message;
          }

        }
      );
    }

  }

  verifyPasscode(): void{
    this.passcodeService.verifyPasscode(this.passcodeVerificationForm.passcode).subscribe(
      data => {
        this.tokenStorageService.saveToken(data.access_token, data.refresh_token, data.expired_time, data.customUserDetails);
        this.router.navigate(['/requests']);
      },
      err => {
        this.mainError = err.error.message;

        if(err.error.returnCode === 'E0008'){
          this.tokenStorageService.signOut();
          this.newStatusLogin();
          this.router.navigateByUrl('/login');         
        }
      }
    );
  }

  countdownTimer(): void{
    setTimeout(() => {
      if (this.counter > 0){
        this.counter --;
        this.countdownTimer();
      }
    }, 1000);
  }

  initiateCountdown(countdownTime: number): void{
    this.counter = countdownTime;
    this.countdownTimer();
  }

  newStatusLogin() {
    this.authService.changeMessage(false);
  }
}
