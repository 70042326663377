import { environment } from 'src/environments/environment';

export class Constants {
    static readonly DATE_FMT = 'yyyy/MM/dd';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} HH:mm`;
    static readonly COMPLETED = 'COMPLETED';
    static readonly DELETION_COMPLETED = 'DELETION_COMPLETED';

    // tokens
    static readonly TOKEN_HEADER_KEY = 'Authorization';
    static readonly ACCESS_TOKEN = 'access_token';
    static readonly REFRESH_TOKEN = 'refresh_token';
    static readonly EXPIRES_AT = 'expired_time';
    static readonly USER_DETAILS = 'user_details';

    // user states
    static readonly UN_AUTHENTICATED = 'un_authenticated';
    static readonly VERIFICATION_PENDING = 'verification_pending';
    static readonly AUTHENTICATED = 'authenticated';
    static readonly PASSCODE_VERIFIED = 'passcode_verified';

    // user roles
    static readonly ROLE_VERIFICATION_PENDING = 'ROLE_PASSCODE_NOT_VERIFY';
    static readonly ROLE_PASSCODE_VERIFIED = 'ROLE_PASSCODE_VERIFIED'

    // abort status
    static readonly NOT_INTIATED_STATUS = 'NOT_INTIATED';

    // request types
    static readonly REQUEST_TYPES = {
        FARMER_DATA_DELETION: 'Delete Farmer Data'
    };

    // Routes
    static readonly API_HOST = environment.apiUrl;

    static readonly API_ROUTES = {
        LOGIN: `${Constants.API_HOST}/login`,

        SEND_PASSCODE: `${Constants.API_HOST}/api/v1/passcode/send`,
        VERIFY_PASSCODE: `${Constants.API_HOST}/api/v1/passcode/verify`,

        SERVICE_REQUESTS: `${Constants.API_HOST}/api/v1/self-service-requests`,

        ABORT_REQUESTS: `${Constants.API_HOST}/api/v1/abort-delete-request`,
        ABORT_REQUEST_STATUS: `${Constants.API_HOST}/api/v1/abort-delete-request/status`
    };
}