<div class='main_container'>
    <div class="box">
        <h3 class="text-center">
            {{ 'UI.ABORT_DELETION_REQUEST.HEADING' | translate }}
        </h3>
        <div class='content'>
            <div class="alertSummary ng-hide" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div *ngIf="!errorMessage && isAbortInitiated">
                <div class="alertSummary info ng-hide" role="alert">
                    <span>{{ 'UI.ABORT_DELETION_REQUEST.STATUS.TEXT' | translate }}</span>
                </div>
            </div>
            <div *ngIf="!errorMessage && deletetionCompleted">
                <div class="alertSummary info ng-hide" role="alert">
                    <span>{{ 'UI.ABORT_DELETION_REQUEST.COMPLETED' | translate }} </span>
                </div>
            </div>
            <div class="alertSummary ng-hide" role="alert" *ngIf="!emailInput">{{ 'UI.ERRORS.MESSAGE.EMAIL_REQUIRED' | translate }}</div>
            <div class="alertSummary ng-hide" role="alert" *ngIf="!reasonInput">{{ 'UI.ABORT_DELETION_REQUEST.REASON_REQUIRED' | translate }}</div>

            
            <div *ngIf="!isAbortInitiated && !deletetionCompleted">
                <form name="form" [formGroup]="abortRequest" novalidate>
                    <div class="row form-group">
                        <div class="offset-sm-1 col-sm-4 text-right">
                            <label class='control-label'>{{ 'UI.COMMON.EMAIL.LABEL' | translate }}</label><span class="mandatory_field">*</span>:
                        </div>
                        <div class="col-sm-4">
                            <input [(ngModel)]="email" name="form.email" formControlName="email" placeholder="{{ 'UI.COMMON.EMAIL.PLACEHOLDER' | translate }}" class="form-control" type="email" pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required/>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="offset-sm-1 col-sm-4 text-right">
                            <label class='control-label'>{{ 'UI.COMMON.REASON.LABEL' | translate }}</label><span class="mandatory_field">*</span>:
                        </div>
                        <div class="col-sm-4">
                            <input [(ngModel)]="reason" name="form.reason" formControlName="reason" placeholder="{{ 'UI.COMMON.REASON.PLACEHOLDER' | translate }}" class="form-control" type="text" required/>
                        </div>
                    </div>
                    <div class="text-center">
                        <button type="button" data-target="#abortConfirmation" class="btn btn-primary" data-toggle="modal">{{'UI.COMMON.CREATE_BUTTON' | translate }}</button>
                    </div>
                </form>
            </div>

            <div class="modal fade" id="abortConfirmation" tabindex="-1" role="dialog" aria-labelledby="abortConfirmationTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="abortConfirmationTitle">{{ 'UI.ABORT_DELETION_REQUEST.WARNING_TITLE' | translate }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {{ 'UI.ABORT_DELETION_REQUEST.WARNING' | translate }}
                        </div>
                        <div class="modal-footer">
                            <button type="buton" class="btn btn-primary" data-dismiss="modal" (click)="submit()">{{'UI.COMMON.CONTINUE_BUTTON' | translate}}</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal">{{'UI.COMMON.CANCEL_BUTTON' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>